import * as React from 'react';
import { Link, Trans } from 'gatsby-plugin-react-i18next';

const Banner = () => {
  return (
    <div className="text-black relative">
      <div className="flex aspect-[9/16] lg:aspect-video w-full justify-center items-center rounded-xl">
        <div className="absolute flex aspect-[9/16] lg:aspect-video w-full justify-center items-center">
          <h1 className="text-center">
            <Trans>We are #cyberrangers</Trans>
          </h1>
        </div>
        <div className="absolute flex aspect-[9/16] lg:aspect-video w-full justify-center items-end p-[3.75rem]">
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <Link
            aria-label="Main content link"
            className="black-arrow"
            to="/about-us#main"
          />
        </div>
        <video
          className="rounded-xl absolute right-0 bottom-0 min-h-full min-w-full w-auto h-auto overflow-hidden bg-cover -z-10 object-cover"
          autoPlay
          muted
          loop
          playsInline
        >
          <source src="/videos/about.webm" type="video/webm; codecs=vp9" />
          <source src="/videos/about.mp4" type="video/mp4; codecs=hevc" />
          <source src="/videos/about.m4v" type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default Banner;
