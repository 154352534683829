import * as React from 'react';
import { Document } from '../../types';

const Documents = ({ documents }: { documents: Document[] }) => {
  return (
    <div className="flex flex-col gap-[1rem] lg:gap-[0.625rem]">
      {documents.map(({ link, title }) => (
        <React.Fragment key={link}>
          <a
            href={link}
            target="_blank"
            className="no-underline text-black"
            rel="noreferrer"
          >
            <div className="flex justify-between items-center h-[1.875rem]">
              <p className="font-semibold">{title}</p>
              <div className="min-w-[20px] h-[20px] bg-[url('../images/document.svg')] bg-center bg-no-repeat" />
            </div>
          </a>
          <hr className="last:hidden" />
        </React.Fragment>
      ))}
    </div>
  );
};

export default Documents;
