import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import Banner from '../components/about/banner';
import Base from '../components/base';
import Sides from '../components/ranges/sides';
import Info from '../components/info';
import { BoardMember } from '../types';
import BoardMembers from '../components/about/boardMembers';
import Card from '../components/card';
import Documents from '../components/about/documents';
import Facts from '../components/facts';
import StrategicPartners from '../components/landing/startegicPartners';
import Nato from '../components/about/nato';
import { formatRawEvent, formatRawDocument, isNonNullable } from '../utilities';
import SEO from '../components/seo';

const AboutUs = ({
  data: { site, natoEvents: rawNatoEvents, documents: rawDocuments },
  location,
}: PageProps<Queries.AboutQuery>) => {
  const { t, language } = useI18next();
  const natoEvents = (rawNatoEvents.nodes ?? []).map(formatRawEvent);
  const documents = (rawDocuments.nodes ?? [])
    .map((node) => {
      if (
        !isNonNullable(node.frontmatter?.file?.publicURL) &&
        !isNonNullable(node.frontmatter?.link)
      ) {
        return undefined;
      }
      return node;
    })
    .filter(isNonNullable)
    .map(formatRawDocument(language));
  const boardMembers: BoardMember[] = (site?.siteMetadata?.board ?? [])
    .filter(isNonNullable)
    .map((member) => ({
      name: member.name ?? 'No Name',
      title: member.title ?? 'No Title',
    }));
  const managementBoardMemebers: BoardMember[] = (
    site?.siteMetadata?.managementBoard ?? []
  )
    .filter(isNonNullable)
    .map((member) => ({
      name: member.name ?? 'No Name',
      title: member.title ?? 'No Title',
    }));
  return (
    <Base location={location}>
      <Sides type="wide">
        <div className="pt-[5.625rem] lg:pt-0">
          <Banner />
        </div>
      </Sides>
      <div id="main" className="text-black">
        <div className="mt-[5.625rem] lg:mt-[8.75rem]">
          <Sides type="content">
            <Info
              id="our-story"
              title={t(
                'Established by the Estonian Ministry of Defence on the 1st of January 2021'
              )}
              link={{
                link: '/ranges',
                text: t('CR14 Cyber Ranges'),
              }}
              darkLine
            >
              <p>
                <Trans>
                  CR14 is proud to offer Cyber Range, exercise automation,
                  digital library and simulation tools and solutions to allow
                  effective and easy planning and deployment of cyber exercises
                  and activities. Our cyber-physical solutions - the power grid,
                  5G networks, military systems, IoT etc. - also support
                  specialised training, particularly for critical infrastructure
                  providers.
                </Trans>
              </p>
            </Info>
            <div className="mt-[5.625rem] lg:mt-[8.75rem]">
              <Facts blackBorder />
            </div>
          </Sides>
          <Sides type="wide">
            <div className="mt-[5.625rem] lg:mt-[8.75rem] flex justify-center">
              <StaticImage
                className="rounded-xl"
                alt={t('About us banner')}
                src="../images/about-image.jpg"
              />
            </div>
          </Sides>
          <Sides type="content">
            <div id="nato-support" className="mt-[5.625rem] lg:mt-[8.75rem]">
              <Card
                title={t('NATO support')}
                description={t(
                  'CR14 is a government-owned and operated entity, which offers cybersecurity-related research and development for domestic and international; private and public sector partners.'
                )}
              >
                <div className="mt-[2rem] lg:mt-0 lg:ml-[3.75rem]">
                  <Nato natoEvents={natoEvents} />
                </div>
              </Card>
            </div>
            <div id="board-members" className="mt-[5.625rem] lg:mt-[8.75rem]">
              <BoardMembers boardMembers={boardMembers} />
            </div>
            <div className="mt-[5.625rem] lg:mt-[8.75rem]">
              <BoardMembers
                boardMembers={managementBoardMemebers}
                isManagement
              />
            </div>
            <div id="documents" className="mt-[5.625rem] lg:mt-[8.75rem]">
              <Card title={t('Documents')}>
                <div className="mt-[2rem] lg:mt-0 lg:ml-[3.75rem]">
                  <Documents documents={documents} />
                </div>
              </Card>
            </div>
            <div id="partners" className="mt-[5.625rem] lg:mt-[8.75rem]">
              <StrategicPartners />
            </div>
            <div className="mt-[5.625rem] lg:mt-[8.75rem]" />
          </Sides>
        </div>
      </div>
    </Base>
  );
};

export default AboutUs;

export const pageQuery = graphql`
  query About($language: String!) {
    site {
      siteMetadata {
        board {
          name
          title
        }
        managementBoard {
          name
          title
        }
      }
    }

    natoEvents: allMarkdownRemark(
      filter: {
        fields: { sourceName: { eq: "event" } }
        frontmatter: { language: { eq: $language } }
      }
    ) {
      nodes {
        html
        frontmatter {
          language
          title
        }
      }
    }
    documents: allMarkdownRemark(
      filter: { fields: { sourceName: { eq: "documents" } } }
    ) {
      nodes {
        frontmatter {
          title
          titleEst
          link
          file {
            publicURL
          }
        }
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => <SEO title="CR14 About us" />;
