import * as React from 'react';

const Card = ({
  title,
  description,
  children,
}: {
  title: string;
  description?: string | null;
  children?: React.ReactNode;
}) => {
  return (
    <div className="border border-solid border-black rounded-xl">
      <div className="flex flex-col lg:flex-row pb-[1.875rem] pt-[1.875rem] pr-[1.25rem] pl-[1.25rem] lg:p-[3.75rem]">
        <div className="basis-1/2 lg:pr-[3.75rem] flex flex-col gap-[1.25rem] lg:gap-[1.5rem]">
          <h3>{title}</h3>
          {description && <p>{description}</p>}
        </div>
        <hr className="hidden lg:block lg:vertical black lg:h-auto" />
        <hr className="lg:hidden border-black mt-[1.875rem]" />
        <div className="basis-1/2 ">{children}</div>
      </div>
    </div>
  );
};

export default Card;
