import * as React from 'react';
import { NatoEvent } from '../../types';

const Nato = ({ natoEvents }: { natoEvents: NatoEvent[] }) => {
  const [selectedIndex, setSelectedIndex] = React.useState<
    undefined | number
  >();

  return (
    <div className="flex flex-col">
      {natoEvents.map(({ title, description }, index) => (
        <React.Fragment key={title}>
          <div>
            <button
              onClick={() => {
                if (index === selectedIndex) {
                  setSelectedIndex(undefined);
                } else {
                  setSelectedIndex(index);
                }
              }}
              type="button"
              className="px-0 bg-transparent w-full flex justify-between items-center group min-h-[3.5rem] lg:min-h-[4rem]"
            >
              <h3
                className={`group-hover:text-primary ${
                  index === selectedIndex ? 'text-primary' : ''
                }`}
              >
                {title}
              </h3>
              <div
                className={
                  index === selectedIndex ? 'pointer selected' : 'pointer'
                }
              >
                <div
                  className={`
                  ${index === selectedIndex ? '-rotate-90' : 'rotate-90'}
                  w-full h-full bg-[url("../images/button-arrow.svg")] bg-contain bg-center bg-no-repeat m-[0.8rem]`}
                />
              </div>
            </button>
            <div
              className={` ${
                index === selectedIndex ? '' : 'hidden'
              } mb-[0,875rem] lg:mb-[1.25rem]`}
            >
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: description }}
                className="mt-[3.75rem] event-content"
              />
            </div>
          </div>
          <hr className="last:hidden" />
        </React.Fragment>
      ))}
    </div>
  );
};

export default Nato;
