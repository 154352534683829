import * as React from 'react';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';

import { BoardMember } from '../../types';

const BoardMembers = ({
  boardMembers,
  isManagement = false,
}: {
  boardMembers: BoardMember[];
  isManagement?: boolean;
}) => {
  const { t } = useI18next();
  return (
    <div className="text-black flex flex-col">
      <h3>
        {isManagement ? (
          <Trans>CR14 Management Board</Trans>
        ) : (
          <Trans>CR14 Advisory Board</Trans>
        )}
      </h3>
      <div className="grid grid-cols-1 lg:grid-cols-4 w-full mt-[3.75rem] lg:mt-[5rem] gap-[2.5rem] lg:gap-[3.75rem]">
        {boardMembers.map(({ name, title }) => (
          <div key={name}>
            <h4 className="my-0">{name}</h4>
            <p className="text-primary font-semibold mt-[0.375rem] lg:mt-[0.625rem]">
              {t(title)}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BoardMembers;
